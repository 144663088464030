$color-primary: #003366;
$color-primary-contrast: #fff;
$color-secondary: #666;
$color-secondary-emphasis: #555;

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
  font-weight: 300;
  line-height: 2;
  text-align: center;
  padding: 0 1em 2em 1em;
  margin: 0 auto;
  max-width: 800px;
  color: $color-secondary;
}

p {
  text-rendering: optimizeLegibility;
  margin: 0 0 1.2em 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 2.2em;
  font-weight: 400;
  margin: 2.2em 0 1em 0;
  line-height: 1.5;
  color: $color-secondary-emphasis;
}

.border {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: .5em;
  background-color: $color-primary;
}

h2 {
  margin: 2.5em 0 1em 0;
}

h3 {
  margin: 2.2em 0 1em 0;
}

header {
  padding-bottom: 2em;
}

header h1 {
  font-size: 2.8em;
  font-weight: 700;
  margin: 1.8em 0 1.2em 0;
  color: $color-primary;
}

a {
  color: $color-secondary;
}

a:visited {
  color: $color-secondary;
}

a.home {
  font-weight: bold;
}

h1 a {
  color: $color-secondary-emphasis;
}


header li {
  padding: 1em 0 0.5em 0;
}

a.button {
  border-style: solid;
  border-width: 2px;
  border-color: #b3b3b3;
  border-radius: 10px;
  color: #b3b3b3;
  padding: 5px 15px;
  min-width: 20em;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  background-color: $color-primary-contrast;
  display: inline-block;
  margin: 1em;
}

a.button:hover {
  color: white;
  background-color: #b3b3b3;
}

a.button.recommend {
  border-color: $color-primary;
  color: $color-primary;
}

a.button.recommend:hover {
  color: white;
  background-color: $color-primary;
}

article {
  text-align: left;
}

article ul {
  list-style-type: disc;
  margin: 1em;
}

article il {
  margin: 1em;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 6em 0 0 0;
  padding: 0;
}

.pure-menu a {
  color: $color-secondary;
}

.pure-menu li a:hover, .pure-menu li a:focus {
  background-color: $color-primary-contrast;
  color: $color-primary;
}

li.active, li.active a, li.active a:hover {
  background-color: $color-primary;
  color: $color-primary-contrast;
}
